import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MsalService } from '@azure/msal-angular';


export function isLoggedInGuard(): boolean | UrlTree {
  const msalService = inject(MsalService);
  const authService = inject(AuthService);
  const router = inject(Router);

  return msalService.instance.getAllAccounts().length === 0
    ? true
    : router.createUrlTree([authService.redirectUrl ?? '/']);
}
