import { DOCUMENT } from '@angular/common';
import { Component, DestroyRef, Inject } from '@angular/core';
import { tap } from 'rxjs';
import { inTeams } from '../../../auth/helpers/teams.helper';
import { TeamsService } from '../../../auth/services/teams.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs/operators';
import { InteractionStatus } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { HASH } from '../../../app.version';


@Component({
  selector: 'pa-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {

  inTeams = inTeams();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private destroyRef: DestroyRef,
    private readonly teamsService: TeamsService,
    private msalbroadCastService: MsalBroadcastService,
    private authService: MsalService,
  ) {
    this.teamsService.theme$
      .pipe(
        takeUntilDestroyed(),
        tap(theme => this.loadStyle(theme)),
      )
      .subscribe();

    this.authService.initialize()
      .subscribe(() => {
        this.msalbroadCastService.inProgress$.pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntilDestroyed(this.destroyRef),
        ).subscribe(() => {
          if (this.authService.instance.getAllAccounts().length === 0) { // Checking if user already logged in or not during page load
            this.authService.loginRedirect(); // redirecting after 5 seconds
          } else {
            // this.router.navigate(['landing-page']); // navigate to dashboard page or landing page if user exists
          }
        });
      });
  }

  async loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    const themeLink = this.document.getElementById('client-theme') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = `${ styleName }.css?version=${ HASH }`;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${ styleName }.css?version=${ HASH }`;

      head.appendChild(style);
    }
  }
}
