import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { init as initSentry } from '@sentry/angular-ivy';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { HASH } from './app/app.version';


if (environment.production) {
  enableProdMode();
}

initSentry({
  dsn: 'https://e3048c85ea094fa4b78df86a3874af4d@o263480.ingest.sentry.io/4504571043577856',
  environment: environment.production ? 'production' : 'development',
  release: `${ HASH }-production-frontend`,
  enabled: environment.production,
  autoSessionTracking: false,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
