<nz-layout class="h-full">
  <nz-header class="flex items-center !px-4 md:!px-8">
    <img class="mx-1 md:mx-4 w-10 h-10" src="assets/img/onward.svg" alt="Logo">
    <div nz-menu nzTheme="dark" nzMode="horizontal" class="header-menu">
      <a nz-menu-item routerLink="project-admin">Projekt Administration</a>
      <a nz-menu-item routerLink="bonus">Prämien</a>
    </div>
  </nz-header>
  <router-outlet></router-outlet>
</nz-layout>
