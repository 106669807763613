import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import de from '@angular/common/locales/de';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { de_DE, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { AppRoutingModule } from './app.routing';
import { TeamsService } from './auth/services/teams.service';
import { AppComponent } from './common/components/app/app.component';
import { NoopCache } from './common/helpers/noop-cache';
import { LayoutModule } from '@angular/cdk/layout';
import { WebLayoutComponent } from './common/components/web-layout/web-layout.component';
import { TeamsLayoutComponent } from './common/components/teams-layout/teams-layout.component';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './auth/auth.providers';
import { NzModalService } from 'ng-zorro-antd/modal';


registerLocaleData(de);

@NgModule({
  declarations: [
    AppComponent,
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ApolloModule,
    LayoutModule,
    MsalModule.forRoot(
      MSALInstanceFactory(),
      MSALGuardConfigFactory(),
      MSALInterceptorConfigFactory(),
    ),
    NzLayoutModule,
    NzMenuModule,
    /* ### Components ### */
    WebLayoutComponent,
    TeamsLayoutComponent,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    NzModalService,
    {
      provide: APP_INITIALIZER,
      useFactory: (teamsService: TeamsService) => () => teamsService.init(),
      deps: [TeamsService],
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new NoopCache(),
          link: httpLink.create({
            uri: '/api/graphql',
          }),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'network-only',
              errorPolicy: 'none',
            },
            query: {
              fetchPolicy: 'network-only',
              errorPolicy: 'none',
            },
            mutate: {
              fetchPolicy: 'network-only',
              errorPolicy: 'none',
            },
          },
        };
      },
      deps: [HttpLink],
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: NZ_I18N, useValue: de_DE },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,
  ],
})
export class AppModule {
}
