import { ApolloCache } from '@apollo/client/core';
import { SafeAny } from '@project-hub/types';


export class NoopCache extends ApolloCache<SafeAny> {

  constructor() {
    super();
  }

  public override restore(): SafeAny {
    return this;
  }

  public override extract() {
    return undefined;
  }

  public override read<T>(): T | null {
    return null;
  }

  public override write() {
    return undefined;
  }

  public override modify(): boolean {
    return true;
  }

  public override diff(): SafeAny {
    return undefined;
  }

  public override watch() {
    return () => {
      // no op
    };
  }

  public override gc() {
    return [];
  }

  public retain(): number {
    return 0;
  }

  public release(): number {
    return 0;
  }

  public override identify() {
    return undefined;
  }

  public override evict(): boolean {
    return false;
  }

  public override reset(): Promise<void> {
    return Promise.resolve();
  }

  public override removeOptimistic() {
    // no op
  }

  public override performTransaction() {
    // no op
  }

  public override transformDocument(document: SafeAny) {
    return document;
  }
}
