import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isLoggedInGuard } from './auth/guards/is-logged-in.guard';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'project-admin' },
  {
    path: 'project-admin',
    loadChildren: () => import('./project-admin/project-admin.routing').then(m => m.ProjectAdminRoutingModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'bonus',
    loadChildren: () => import('./bonus/bonus.routing').then(m => m.BonusRoutingModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'profile',
    loadComponent: () => import('./common/components/profile/profile.component').then(c => c.ProfileComponent),
    canActivate: [MsalGuard],
  },
  {
    path: 'login',
    loadComponent: () => import('./common/components/login/login.component').then(c => c.LoginComponent),
    canActivate: [isLoggedInGuard],
  },
  {
    path: 'login-failed',
    loadComponent: () => import('./common/components/failed/failed.component').then(c => c.FailedComponent),
    canActivate: [isLoggedInGuard],
  },
  {
    path: 'auth',
    component: MsalRedirectComponent,
    canActivate: [isLoggedInGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
